

.about {
    display: inline;
    align-items: center;
    min-height: 100vh;
  }

.ruleContainer{
    align-items: center;
    background-color: rgb(223, 223, 223);
    padding-bottom: 10px;
    padding: 5px;
    
}

.ruleTitle{
    text-align: center;
    padding-top: 5px;
}

.rule {
    display: grid;
    margin: 10px;
    background-color: white;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }

.originContainer{
    align-items: center;
    background-color: rgb(255, 255, 255);
    padding-bottom: 10px;
    margin:5px;
}

.origin{
    display: grid;
    margin: 10px;
    background-color: white;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
}

.originTitle{
    padding: 10px;
    text-align: left;
}

.creatorImage{
   display: flex;
    background-image: url("../../../public/images/creator.JPG");
    background-color: aqua;
    background-size:cover ;
    min-width: 170px;
    min-height: 170px;
}

.imageContainer{
    width: 170px;
    height:170px;
}

.imageBlock{
    display: flex;
    justify-content: center;
}

.aboutTitle{
    margin:10px
}

.subTitle{
    padding-top: 5px;
    margin: 10px;
}

.message{
    text-align: center;
    padding: 10px;
}