
square {
    min-height:70px;
    min-width: 70px;
    border: 2px solid black;
    border-radius: 10px;
}
.black {
    background-color: black;
}
.white{
    background-color: white;
}
#square-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
   }