
big-square {
    min-height: 300px;
    min-width: 300px;
    border: 2px solid black;
    border-radius: 30px;
}
.white{
    background-color: white;
}

.black {
    background-color: black;
}

#square-container {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
   }

h2{
    font-size: xx-large;
    text-align: center;
    margin-bottom: 20px;
}

.timerDisplay{
    width: 70%;
    background: #ffffff;
    margin: auto;
    font-family: 'Roboto mono',monospace;
    color: #171b1d;
    font-size: 40px;
    display: flex;
    align-items: center;
    justify-content: center;    
    border-radius: 5px;
    box-shadow: 0 0 20px rgba(18, 19, 20, 0.25);
}