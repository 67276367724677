h3 {
  padding-bottom: 2px;
}

.browseContainer {
  grid-template-columns: repeat(2, 1fr);
  display: grid;
  margin-bottom: 20px;
}

.gameIcon {
  display: flex;
  margin-right: 30px;
  justify-content: right;
  font-size: 2.5rem;
  align-items: flex-end;
}

.browseSection {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 30px;
  margin-top: 20px;
}

.browseTitle {
  margin-bottom: -5px;
}
.gameContainer {
  margin: 5px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
}

.gameSection {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  text-align: center;
  background-color: rgb(232, 232, 232);
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-top: 15px;
}

.chooseButton {
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 255, 255);
  font-size: larger;
  transition-duration: 0.4s;
  min-height: 150px;
  min-width: 100px;
  color: black;
  margin: 10px;
  border-radius: 20px;
  font-size: 20px;
}

h4 {
  margin-top: 5px;
  color: black;
}

.chooseButton:hover {
  background-color: rgb(217, 217, 217);
  color: rgb(64, 161, 93);
  border-color: rgb(255, 255, 255);
}
