.footer{
height: 50px;
text-align:center;
left:0;
bottom:0px;
right:0px;
position:relative;
background-color: black;
  }

.footerTitle{
  padding-top: 10px;
    color: white;
}