.navbar {
  background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
}

.fa-square {
    margin-left: 10px;
    
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}


.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
  
}

  .nav-menu {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    width: 100vw;
    bottom: 0;
    position:fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    display:block;
    height:100vh;
    background: #ffffff;
    padding:10px;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }
  .gameMenu{
    display: grid;
    background-color: rgb(232, 232, 232);
    border-radius: 20px;
    grid-template-columns: repeat(1, 1fr);
    height: 70vh;
    grid-gap: 0px;
    align-items: center;
  }

  .nav-links {
    display: flex;
    align-items:center;
    justify-content: left;
    background: rgb(255, 255, 255);
    
    transition-duration: 0.4s;
    max-height: 60px;
    color:black;
    border-radius: 20px;
    margin: 5px;
  }

@media (max-width: 480px) {
  
}

  .nav-links:hover {
    
    background-color: rgb(217, 217, 217);
    color: rgb(64, 161, 93);
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(5%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-110%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #fff;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #242424;
    transition: 250ms;
  }
